
//
// Datepicker
//
// flatpickr
// .datepicker {
//   border: 1px solid var(--#{$prefix}border-color);
// }
.flatpickr-input{
  &[readonly]{
    background-color: $input-bg;
  }
}

.input{
  &[readonly]{
    background-color: $input-bg;
  }
}

.flatpickr-months, .flatpickr-weekdays{
  background-color: $primary;
}

span.flatpickr-weekday{
  color: $white;
  font-weight: $font-weight-medium;
}

.flatpickr-current-month{
  .flatpickr-monthDropdown-months{
    &:hover{
      background-color: transparent !important;
    }
  }
}

.flatpickr-am-pm, .numInput, .numInputWrapper{
  &:focus, &:hover{
    background-color: transparent !important;
  }
}


.flatpickr-weekdays{
  height: 36px !important;
  border-bottom: 1px solid var(--#{$prefix}border-color);
}

.flatpickr-day{
  color: var(--#{$prefix}body-color);
  &:hover, &:focus{
    background-color: rgba(var(--#{$prefix}white), 0.5) !important;
  }
  &.today{
    border-color: $primary !important;
    background-color: rgba($primary, 0.1);
    &:hover, &:focus{
      color: var(--#{$prefix}body-color) !important;
      background-color: rgba($primary, 0.15) !important;
    }
  }
  &.selected{
    background-color: $primary !important;
    border-color: $primary !important;
    color: $white !important;
  }

  &.flatpickr-disabled,
  &.flatpickr-disabled:hover,
  &.prevMonthDay,
  &.nextMonthDay,
  &.notAllowed,
  &.notAllowed.prevMonthDay,
  &.notAllowed.nextMonthDay{
    color: var(--#{$prefix}gray-500) !important;
  }

  &.inRange, 
  &.prevMonthDay.inRange, 
  &.nextMonthDay.inRange,
  &.today.inRange,
  &.prevMonthDay.today.inRange,
  &.nextMonthDay.today.inRange,
  &:hover,
  &.prevMonthDay:hover,
  &.nextMonthDay:hover,
  &:focus, 
  &.prevMonthDay:focus, 
  &.nextMonthDay:focus{
    background-color: var(--#{$prefix}tertiary-bg) !important;
    border-color: var(--#{$prefix}border-color);
  }
  &.inRange{
    box-shadow: -5px 0 0 var(--#{$prefix}light), 5px 0 0 var(--#{$prefix}light);
  }
  &.selected, 
  &.startRange, 
  &.endRange, 
  &.selected.inRange, 
  &.startRange.inRange, 
  &.endRange.inRange, 
  &.selected:focus, 
  &.startRange:focus, 
  &.endRange:focus, 
  &.selected:hover, 
  &.startRange:hover, 
  &.endRange:hover, 
  &.selected.prevMonthDay, 
  &.startRange.prevMonthDay, 
  &.endRange.prevMonthDay, 
  &.selected.nextMonthDay, 
  &.startRange.nextMonthDay, 
  &.endRange.nextMonthDay{
    background-color: $primary !important;
    border-color: $primary !important;
    color: $white !important;
  }
}

.flatpickr-months{
  .flatpickr-prev-month, .flatpickr-next-month, .flatpickr-month{
    color: rgba($white,0.9) !important;
    fill: rgba($white,0.9) !important;
  }
}

.flatpickr-monthDropdown-month{
  color: rgba($black, 0.8);
}
.flatpickr-current-month{
  input.cur-year{
    &[disabled], &[disabled]:hover{
      color: rgba($white,0.9) !important;
    }
  }
}


.flatpickr-time {
  input, .flatpickr-time-separator, .flatpickr-am-pm{
    color: var(--#{$prefix}body-color) !important;
  }
}

.flatpickr-calendar{
  background-color: var(--#{$prefix}white) !important;
  border: 1px solid var(--#{$prefix}border-color);
  box-shadow: 1px 0 0 var(--#{$prefix}box-shadow), -1px 0 0 var(--#{$prefix}box-shadow), 0 1px 0 var(--#{$prefix}box-shadow), 0 -1px 0 var(--#{$prefix}box-shadow), 0 3px 13px rgba(0, 0, 0 , 0.08) !important;
  &.hasTime{
    .flatpickr-time{
      border-top: 1px solid var(--#{$prefix}border-color) !important;
    }
  }
}

.flatpickr-months{
  border-radius: 5px 5px 0px 0px;
  .flatpickr-prev-month, .flatpickr-next-month{
    &:hover{
      svg{
        fill: rgba($white,0.9) !important;
      }
    }
  }
}